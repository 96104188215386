import React from 'react';
import GdprRequestForm from '../../components/GdprRequestForm';
import Logo from '../../components/Logo';
import FooterLogo from '../../components/FooterLogo';
import Contact from '../../components/Contact';
import withIntl from '../../i18n/withIntl';

import '../../../assets/styles/Web.scss';

const Gdpr = () => (
  <>
    <section className="Section Section--contact Section--themeLight">
      <div className="Section-main">
        <Logo />
        <h2 className="Section-mainTitle">Chceme být stále ve spojení</h2>
        <p className="Section-text">
          Vaše osobní údaje jsou pro nás jediným možným spojením, kterého si velice vážíme. Na
          základě vyplněním údajů níže Vám zašleme e-mail s podrobným popisem pravidel naší symbiózy
          dle obecného nařízení ochrany osobních údajů (GDPR).
        </p>
        <GdprRequestForm />
      </div>
    </section>
    <footer className="Footer">
      <FooterLogo />
      <Contact />
    </footer>
  </>
);

export default withIntl(Gdpr, 'cs');
