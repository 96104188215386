import React, { useState } from 'react';
import { Formik } from 'formik';
import Modal from './Modal';
import SubmitButton from './SubmitButton';
import FormikTextField from './FormikTextField';
import LocalizedTag from '../i18n/LocalizedTag';
import { gdprFormScheme } from '../utils/validations';
import { postData, getData } from '../utils/fetch';
import FormikCheckbox from './FormikCheckbox';

const GdprRequestForm = () => {
  const [gdprChecked, setGdprChecked] = useState(false);
  const [modalText, setModalText] = useState(undefined);
  return (
    <>
      {modalText && <Modal closeModal={() => setModalText(undefined)} text={modalText} />}
      <Formik
        initialValues={{
          name: '',
          email: '',
          address: '',
          birthDate: '',
          tender: '',
        }}
        validationSchema={gdprFormScheme}
        onSubmit={(values, formikActions) => {
          const parsedValues = {
            ...values,
            confirmed: true,
            confirmDate: new Date().getTime(),
            event: 'new web registration',
          };
          postData(`${process.env.FIREBASE_API_URL}/public/gdpr/request`, parsedValues).then(
            (response) => {
              formikActions.setSubmitting(false);
              formikActions.resetForm();
              setModalText(
                response.result === 'already exists'
                  ? 'Váš souhlas již evidujeme'
                  : 'Děkujeme za odeslání GDPR formuláře'
              );
            }
          );
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="ContactForm">
            <FormikTextField name="name" floatingLabel="formName" />
            <FormikTextField name="birthDate" floatingLabel="formBirthDate" />
            <FormikTextField name="address" floatingLabel="formAddress" />
            <FormikTextField name="tender" floatingLabel="formTender" />
            <LocalizedTag id="gdprRulesRequest" type="p" className="Section-text" />
            <FormikTextField
              name="email"
              floatingLabel="formEmail"
              onBlur={() => {
                getData(`${process.env.FIREBASE_API_URL}/public/gdpr/check/${values.email}`)
                  .then((response) => {
                    if (response.error) {
                      setGdprChecked(false);
                      setFieldValue('agreement', false);
                    } else {
                      setGdprChecked(true);
                      setFieldValue('agreement', true);
                    }
                  })
                  .catch(() => {
                    setGdprChecked(false);
                    setFieldValue('agreement', false);
                  });
              }}
            />
            <FormikTextField
              name="country"
              floatingLabel="formCountry"
              style={{ visibility: 'hidden', position: 'absolute' }}
            />
            {!gdprChecked && (
              <FormikCheckbox
                name="agreement"
                floatingLabel={
                  'Souhlasím, aby společnost SQN s.r.o., se sídlem Kubelíkova 1224/42, 130 00 Praha 3, IČ 04965850, (dále jen „správce údajů”), zpracovávala mnou poskytnuté osobní údaje, a to v souladu s obecným nařízením o ochraně osobních údajů [EU] 2016/679. Pravidla o ochraně osobních údajů naleznete <a href="/gdpr-rules" target="_blank">zde</a>.'
                }
              />
            )}
            <SubmitButton isSubmitting={isSubmitting} sendMsg="logIn" />
          </form>
        )}
      </Formik>
    </>
  );
};

export default GdprRequestForm;
